import { styled } from "@mui/material";
import { breakpoint } from "../Breakpoints/breakpoints";
import { Link } from "gatsby";

export const PageNavigation = styled("div")`
  display: flex;
  padding: 10px 0px;
  margin: 20px 0;
  width: 100%;
  bottom: 0px;

  ${breakpoint(
    "large+",
    `
      width: 80%;
      padding: 10px 10%;
    `
  )}
`;

export const PageNavPrev = styled(Link)`
  display: flex;
  flex-direction: column;

  ${breakpoint("medium+", ` max-width: min(40%, 300px); `)}

  div {
    display: flex;
    align-items: center;
    font-size: 2.5rem;

    svg {
      display: block;
      width: 25px;
      height: 25px;
    }
  }
`;

export const PageNavNext = styled(Link)`
  display: flex;
  flex-direction: column;
  text-align: end;
  margin-left: auto;
  ${breakpoint("medium+", ` max-width: min(40%, 300px); `)}

  div {
    display: flex;
    align-items: center;
    font-size: 2.5rem;
    margin-left: auto;

    svg {
      display: block;
      width: 25px;
      height: 25px;
    }
  }
`;

export const PageNavDirection = styled("span")`
  font-size: 2.5rem;
  ${breakpoint("medium-", `font-size: 1.5rem;`)}
`;

export const PageNavPrevName = styled("span")`
  font-size: 2rem;
  margin-left: 25px;
`;

export const PageNavNextName = styled("span")`
  font-size: 2rem;
  margin-right: 25px;
`;
