import React, { useState, useContext } from "react";
import { MenuItem, TextField, styled } from "@mui/material";
import useWindowDimensions from "../utils/windowSize";

export default function DropdownMenu({
  index,
  setIndex,
  options,
}: {
  index: number | null;
  setIndex: React.Dispatch<React.SetStateAction<number | null>>;
  options: { title: string; url: string }[];
}) {
  const handleChange = (event: any) => {
    setIndex(event.target.value as number);
  };
  const { width } = useWindowDimensions();

  return width && width < 650 ? (
    <StyledTextField
      select
      label="Change Project Type"
      onChange={handleChange}
      className="dropDownMenu"
      InputLabelProps={{ style: { color: "#ffb310" } }}
      InputProps={{ style: { color: "#ffb310" } }}
    >
      {options.map(({ title }, i) => (
        <StyledMenuItem key={i} value={i} selected={i === index}>
          {title}
        </StyledMenuItem>
      ))}
    </StyledTextField>
  ) : null;
}

const StyledTextField = styled(TextField)`
  color: #ffb310;
  font-family: Helvetica;
  font-weight: 400px;
  width: 240px;
  margin: 10px auto 10px;
  display: flex;

  > * {
    font-size: 15.2px !important;
  }

  > .MuiInputBase-root {
    > .MuiSvgIcon-root {
      color: #ffb310;
    }
    > .MuiOutlinedInput-notchedOutline {
      border-color: #ffb310;
    }
  }

  &:hover {
    > .MuiInputBase-root {
      > .MuiSvgIcon-root > .MuiOutlinedInput-notchedOutline {
        border-color: #ffb310;
        border-width: 2px;
      }
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 15px;
`;
