import { styled } from "@mui/material";
import { breakpoint } from "../Breakpoints/breakpoints";

export const DeliverableIframe = styled("iframe")`
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  margin: 0 auto;
  border: 0px;
  position: relative;

  ${breakpoint(
    "medium-",
    `
      padding: 0;
      min-height: 40vh;
    `
  )}
`;

export const NoScrollContainer = styled("div")`
  height: calc(100vh);
  font-family: Helvetica;
  font-weight: 300;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;

  ${breakpoint(
    "medium+",
    `
      padding: 0 10%;
    `
  )}

  ${breakpoint(
    "medium-",
    `
      height: auto;
      padding: 16px;
      gap: 20px;
    `
  )}
`;

export const IframeWrapper = styled("div")`
  padding: 0 2% 1%;
  height: 85%;
  width: 100%;
  flex-shrink: 1;

  ${breakpoint(
    "medium-",
    `
      padding: 0;
      height: auto;
      margin: 16px 0;
    `
  )}
`;

export const HeaderTextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  > p {
    margin: 0;
  }

  ${breakpoint(
    "small-",
    `
       > strong {
        text-align: center;
        margin-bottom: 16px;
      }

     > p {
        display: flex;
        flex-direction: column;
        gap: 16px;
        text-align: left;

        span {
          // text-align: justify;
        }
      }
    `
  )}

  ${breakpoint(
    "xsmall",
    `
      > p {
        gap: 12px;
      }
    `
  )}
`;
